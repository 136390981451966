import { chakra, Container, Heading, Text } from "@chakra-ui/react"
import * as React from "react"
import Team from "../components/about/team"
import Partner from "../components/about/partner"
import MasterLayout from "../layouts/master"

const AboutUs = (props) => {
    return <MasterLayout>
        <Container maxW={'6xl'}>
            <Heading
                lineHeight={1.1}
                fontWeight={600}
                fontSize={{ base: "3xl", sm: "3xl", lg: "3xl", md: "3xl" }}
            >
                <Text
                    as={"span"}
                    position={"relative"}
                    _after={{
                        content: "''",
                        width: "full",
                        height: "30%",
                        position: "absolute",
                        bottom: 1,
                        left: 0,
                        bg: "#8fe7a3",
                        zIndex: -1,
                    }}
                >
                    Our Story,
                </Text>
            </Heading>
            <Text
                pt={'4'}
                mb={'12'}
            >
                <chakra.h2
                    fontWeight={"bold"}
                    fontSize={"0.96rem"}
                >
                    SunoKitaab is an education startup that teaches students digitally, without the internet and smartphone at their homes with personal devices
                </chakra.h2>{" "}Founded in Villages of Rajasthan in 2019, to bring educational content in the most affordable technology. We are building SunoKitaab, an audio-based offline learning device to bring school syllabus in the form of podcasts and audio lectures, added with Music and sound effects. The device can be updated for next class content with OTA update over our mobile app.
                We got the idea while working on a rural fellowship in Churu Rajasthan. In schools, students are not able to take classes due to a lack of teachers in schools and lack of technology in their hands. As we have experience in audio production we thought that the audio format of content can be used to teach students. We started creating content in our voice and piloted it. Currently, we are working with more than 150 teachers and created 10000+ hours of content based on the school syllabus.
            </Text>
        </Container>
        <Team />
        <Partner />
    </MasterLayout>
}

export default AboutUs
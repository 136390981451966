import React from "react";
import {
  Stack,
  Container,
  SimpleGrid,
  Text,
  chakra,
  VStack,
  HStack,
  Heading,
  Icon,
  Box,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { useStaticQuery, graphql } from "gatsby";
import Marquee from "react-fast-marquee";

const Team = () => {
  const imageData = useStaticQuery(IMAGE_QUERY);
  const coreTeamData = [
    {
      name: "Ajay Vishwakarma",
      role: "Founder & CEO",
      image: "ajay.png",
      links: [
        {
          icon: FaLinkedinIn,
          link: "https://www.linkedin.com/in/ajay9911/",
        },
        {
          icon: BsTwitter,
          link: "https://twitter.com/ajayvskrma",
        },
        {
          icon: BsInstagram,
          link: "https://www.instagram.com/ajay.vi",
        },
      ],
    },
    {
      name: "Tanu Aggarwal",
      role: "Co-Founder & COO",
      image: "tanu.png",
      links: [
        {
          icon: FaLinkedinIn,
          link: "https://www.linkedin.com/in/tanu-aggarwal-a5601a130",
        },
        {
          icon: BsTwitter,
          link: "https://twitter.com/Aggarwaltanu8",
        },
        {
          icon: BsInstagram,
          link: "https://www.instagram.com/tanu826/",
        },
      ],
    },
  ];

  const TeamBox = (data) => {
    const image = getImage(
      imageData.allFile.edges.find((e) =>
        e.node.absolutePath.endsWith(data.image)
      ).node.childImageSharp.gatsbyImageData
    );
    return (
      <Stack spacing={6} alignItems={"center"}>
        <GatsbyImage
          alt={data.name}
          image={image}
          style={{ borderRadius: "100%" }}
        />
        <VStack spacing={0}>
          <chakra.h2
            textAlign={"center"}
            fontWeight={"bold"}
            fontSize={{
              lg: "1.6rem",
              md: "1.8rem",
              base: "2rem",
              sm: "1.6rem",
            }}
          >
            {data.name}
          </chakra.h2>
          <chakra.h4
            textAlign={"center"}
            fontSize={{ lg: "1rem", md: "1rem", sm: "1rem" }}
            fontWeight={"bold"}
            color={"blackAlpha.600"}
          >
            {data.role}
          </chakra.h4>
        </VStack>
        <HStack spacing={4}>
          {data.links.map((link) => {
            return (
              <a href={link.link}>
                <Icon as={link.icon} color={"green.500"} w={5} h={5} />
              </a>
            );
          })}
        </HStack>
      </Stack>
    );
  };

  return (
    <>
      <Container maxW={"6xl"}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "3xl", lg: "3xl", md: "3xl" }}
        >
          <Text
            as={"span"}
            position={"relative"}
            _after={{
              content: "''",
              width: "full",
              height: "30%",
              position: "absolute",
              bottom: 1,
              left: 0,
              bg: "#8fe7a3",
              zIndex: -1,
            }}
          >
            Meet Our Team,
          </Text>
        </Heading>
        <SimpleGrid
          py={24}
          columns={{ lg: 2, md: 2, base: 1, sm: 2 }}
          px={{ lg: 0, md: 0, sm: 86 }}
          spacing={{ lg: 36, md: "94px", sm: 10, base: 10 }}
        >
          {coreTeamData.map((data) => {
            return TeamBox(data);
          })}
        </SimpleGrid>
      </Container>
      <Box>
        <Marquee speed={50}>
          {imageData.allFile.edges.map((e) => {
            if (e.node.absolutePath.includes("team")) {
              return (
                <GatsbyImage
                  image={getImage(e.node.childImageSharp.gatsbyImageData)}
                  style={{
                    borderRadius: "100%",
                    width: 90,
                    height: 90,
                    margin: 16,
                  }}
                />
              );
            }
            return <></>;
          })}
        </Marquee>
        <Marquee direction="right" speed={50}>
          {imageData.allFile.edges.map((e) => {
            if (e.node.absolutePath.includes("team")) {
              return (
                <GatsbyImage
                  image={getImage(e.node.childImageSharp.gatsbyImageData)}
                  style={{
                    borderRadius: "100%",
                    width: 90,
                    height: 90,
                    margin: 16,
                  }}
                />
              );
            }
            return <></>;
          })}
        </Marquee>
      </Box>
    </>
  );
};

export default Team;

export const IMAGE_QUERY = graphql`
  query ImageQuery {
    allFile {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          absolutePath
        }
      }
    }
  }
`;
